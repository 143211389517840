import Iconss from "../../assets/images/iconss.png";
import SquareIcon from "../../assets/images/square-icon.png";
import WhoCanJoinBg from "../../assets/images/who-can-join-bg.png";

const WhoCanJoin = () => {
  return (
    <section className="w-full flex justify-center py-10 md:py-28 relative">
      <div className="w-4/5 space-y-10">
        <h4
          data-aos="fade-up"
          className="text-center text-[#EFC166] text-2xl sm:text-[35px] md:text-[40px] lg:text-[53px] font-black font-['Axiforma'] uppercase leading-tight"
        >
          Kİmler <br />{" "}
          <span className="font-medium tracking-[0.23em]">Başvurabİlİr?</span>
        </h4>
        <ul className="flex flex-col items-center space-y-4 md:space-y-7 text-center">
          <li
            data-aos="fade-left"
            className="text-white  text-xs md:text-base lg:text-[20px] xl:text-[23px] font-medium font-['Axiforma'] flex"
          >
            <img
              className="w-[10px] h-[10px] sm:w-[16px] sm:h-[16px] md:w-[22px] md:h-[22px] mr-1 md:mr-2 mt-[2px]"
              src={SquareIcon}
              alt="square_icon"
            />{" "}
            Üniversite 4. sınıf, Yeni Mezun, Yüksek Lisans veya Doktora
            öğrencisi,
          </li>
          <li
            data-aos="fade-left"
            className="text-white  text-xs md:text-base lg:text-[20px] xl:text-[23px] font-medium font-['Axiforma'] flex"
          >
            <img
              className="w-[10px] h-[10px] sm:w-[16px] sm:h-[16px] md:w-[22px] md:h-[22px] mr-1 md:mr-2 mt-[2px]"
              src={SquareIcon}
              alt="square_icon"
            />{" "}
            1 yıldan az iş deneyimine sahip,
          </li>
          <li
            data-aos="fade-left"
            className="text-white  text-xs md:text-base lg:text-[20px] xl:text-[23px] font-medium font-['Axiforma'] flex"
          >
            <img
              className="w-[10px] h-[10px] sm:w-[16px] sm:h-[16px] md:w-[22px] md:h-[22px] mr-1 md:mr-2 mt-[2px]"
              src={SquareIcon}
              alt="square_icon"
            />
            2000 yılı ve sonrası doğumlu adaylar Eksim Genç Enerji Programı’na
            başvurabilir.
          </li>
        </ul>
      </div>
      <img
        className="w-3/5 absolute -left-1/4 -top-1/3 z-[-1]"
        src={WhoCanJoinBg}
        alt=""
      />
      <img
        className="w-[120px] sm:w-[170px] md:w-[200px] lg:w-[240px] xl:w-[260px] absolute right-10 z-[-1]"
        src={Iconss}
        alt=""
      />
    </section>
  );
};
export default WhoCanJoin;
