import HeaderRightImg from "../../assets/images/header-right.png";
import HeaderRighMasktImg from "../../assets/images/header-right-mask.png";
import HeaderBottomImg from "../../assets/images/header-bg-mobile-bottom.png";
import ArrowRight from "../../assets/images/arrow-right.png";
import BannerTop from "../../assets/images/banner-top.png";
import LogoSlider from "../LogoSlider";

import { isMobile } from "react-device-detect";
import { cn } from "../../utils/cn";

const Header = () => {
  const isLandscape = window.innerWidth > window.innerHeight;

  console.log(isMobile, isLandscape);
  return (
    <section
      className={cn(
        "relative w-full h-[90vh] flex justify-center bg-[url('../../assets/images/header-bg-mobile.png')] sm:bg-[url('../../assets/images/header-bg.png')] bg-contain sm:bg-cover bg-top sm:bg-center bg-no-repeat",
        { "h-screen": isMobile },
        { "w-auto": isMobile && isLandscape }
      )}
    >
      <img
        className="hidden sm:block absolute aspect-video h-full top-0 right-0 z-30 pointer-events-none"
        src={HeaderRighMasktImg}
        alt="header_right_mask_image"
      />

      <img
        className="hidden sm:block absolute h-full aspect-video top-0 right-0 drop-shadow-lg z-10"
        src={HeaderRightImg}
        alt="header_right_image"
      />
      <img
        className="block sm:hidden absolute h-auto w-full bottom-0 left-0"
        src={HeaderBottomImg}
        alt="header_bottom_image"
      />

      <div
        className={cn(
          "hidden sm:flex absolute z-10 top-10 left-10 items-center justify-center w-[20vw] h-[20vw] lg:w-[160px] lg:h-[160px] bg-[url('../../assets/images/header-image3.png')] bg-contain bg-center bg-no-repeat",
          {
            "w-[80px] h-[80px] top-6 left-6": isMobile && isLandscape,
          }
        )}
      >
        <p className="text-[11px] lg:text-[17px] text-white font-black rotate-[346deg]">
          5.DÖNEM
        </p>
      </div>

      <div
        className={cn("absolute bottom-10 sm:bottom-24 left-0 w-full z-20", {
          "sm:bottom-14": isMobile && isLandscape,
        })}
      >
        <div
          className={cn("w-full space-y-3", {
            "space-y-1": isMobile && isLandscape,
          })}
          lang="tr"
        >
          <div className="flex flex-col items-center sm:items-start sm:pl-12 flex-1 space-y-4 gap-5 sm:gap-0">
            <h2
              data-aos="fade-right"
              className={cn(
                "text-white text-[6vw] lg:text-5xl font-black leading-none tracking-normal whitespace-nowrap",
                {
                  "text-[5vh] leading-tight": isMobile && isLandscape,
                }
              )}
            >
              <span className="text-[#F0AB18]">KARİYERİN İÇİN</span>
              <br /> {` `}BİTMEYEN
              <br className="hidden sm:block" />
              {` `}
              ENERJİNİN ADRESİ
            </h2>

            <a
              className={cn(
                "w-fit self-start ml-6 sm:ml-0 text-black font-black text-lg bg-[#FFA600] rounded-lg p-2 hover:bg-[#FFA600]/80 transition-all duration-200",
                {
                  "text-sm": isMobile && isLandscape,
                }
              )}
              href="https://eksim.hrpeak.com/2bwv8cdy6qfnyqzpks6ykmuu5x.job"
              target="blank"
            >
              HEMEN BAŞVUR
            </a>
          </div>
          <div
            className={cn("flex flex-col gap-5", {
              "gap-2": isMobile && isLandscape,
            })}
          >
            <div
              className={cn(
                "flex flex-col space-y-0 sm:space-y-2 pl-6 sm:pl-12",
                {
                  "sm:space-y-0": isMobile && isLandscape,
                }
              )}
            >
              <div className="flex gap-2 items-center">
                <img
                  data-aos="fade-right"
                  data-aos-offset="0"
                  className="w-[60px]"
                  src={ArrowRight}
                  alt="Email"
                />
                <p
                  data-aos="fade-left"
                  data-aos-offset="0"
                  className={cn(
                    "text-base text-[15px] text-white  whitespace-nowrap font-bold",
                    {
                      "text-sm": isMobile && isLandscape,
                    }
                  )}
                >
                  eksimgencenerji.com
                </p>
              </div>
              <h6
                data-aos="fade-up"
                data-aos-offset="0"
                className="text-white  text-lg sm:text-xs font-thin whitespace-nowrap"
              >
                Son Başvuru Tarihi:{" "}
                <span className="text-lg font-extrabold text-[#3C9890]">
                  28 Şubat 2025
                </span>
              </h6>
            </div>
            <div className="relative w-full sm:w-9/12 bg-white sm:pr-48 sm:pl-3">
              <img
                className="absolute top-0 right-0 sm:right-64 -translate-y-full h-10 sm:h-20"
                src={BannerTop}
                alt=""
              />
              <LogoSlider />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Header;
