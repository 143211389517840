import { useRef, useState, useCallback } from "react";
import PlayButton from "../../assets/images/play-btn.png";
import Icon1 from "../../assets/images/VideoSectionIcon1.png";
import Icon2 from "../../assets/images/VideoSectionIcon2.png";
import Icon3 from "../../assets/images/VideoSectionIcon3.png";
import PurpleCircle from "../../assets/images/purple-circle.png";
import YouTube from "react-youtube";
import { HiPause } from "react-icons/hi";

const VideoSection = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const playerRef = useRef(null);

  const opts = {
    height: "100%",
    width: "100%",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
      modestbranding: 1,
      controls: 1,
      rel: 0,
      showinfo: 0,
      iv_load_policy: 3,
    },
  };

  const handleVideoControl = useCallback(() => {
    if (playerRef.current) {
      if (isPlaying) {
        playerRef.current.internalPlayer.pauseVideo();
      } else {
        playerRef.current.internalPlayer.playVideo();
      }
    }
  }, [isPlaying]);

  const onStateChange = (event) => {
    // YouTube Player States:
    // -1 (unstarted)
    // 0 (ended)
    // 1 (playing)
    // 2 (paused)
    // 3 (buffering)
    // 5 (video cued)
    setIsPlaying(event.data === 1);
  };

  const isMobile = window.innerWidth < 768;

  return (
    <section className="w-full flex justify-center py-16">
      <div className="w-4/5 max-w-screen-xl flex flex-col items-center justify-center space-y-10">
        <h4
          data-aos="fade-up"
          className="text-base md:text-[20px] lg:text-[25px] xl:text-[30px] text-[#E5AE41] font-['Axiforma'] font-black uppercase text-center leading-normal"
          lang="tr"
        >
          Eksim Holding Genç Enerji İle <br /> Yeni Çalışma Arkadaşlarını
          Arıyor!
        </h4>
        <p
          data-aos="fade-up"
          className="w-full sm:w-3/5 text-white text-center text-xs sm:text-sm md:text-base lg:text-lg xl:text-xl font-normal font-['Axiforma']"
        >
          Enerjiden gıdaya yenilenebilir kaynaklardan sermaye yatırımına kadar
          farklı sektörlerde deneyim kazanma fırsatına davetlisin.
        </p>
        <p
          data-aos="fade-up"
          className="w-full sm:w-3/5 text-white text-center text-xs sm:text-sm md:text-base lg:text-lg xl:text-xl font-normal font-['Axiforma']"
        >
          Eğitim programları ile kendini geliştirebileceğin ve kariyer
          yolculuğuna güçlü bir başlangıç yapabileceğin Eksim Genç Enerji yeni
          mezun işe alım programı burada seni bekliyor!
        </p>
        <p
          data-aos="fade-up"
          className="w-full sm:w-3/5 text-white text-center text-xs sm:text-sm md:text-base lg:text-lg xl:text-xl font-normal font-['Axiforma']"
        >
          28 Şubat tarihine kadar başvurunu gerçekleştir, enerji dolu bir
          kariyer için ilk adımını at.
        </p>
        <div data-aos="zoom-in" className="w-full relative">
          <div
            className="group video w-full h-[200px] sm:h-[350px] md:h-[400px] lg:h-[550px] xl:h-[718px] rounded-[30px] bg-white border-[#00E2D7] border-solid border-[3px] flex justify-center items-center z-[100] relative overflow-hidden
          onClick={handleVideoControl}
          "
          >
            <YouTube
              ref={playerRef}
              videoId="DmZIIRTGpv4"
              opts={opts}
              onStateChange={onStateChange}
              className="w-full h-full"
              iframeClassName="w-full h-full"
            />

            {!isPlaying && (
              <img
                onClick={handleVideoControl}
                className="w-[70px] sm:w-[100px] md:w-[140px] lg:w-[160px] xl:w-[193px] absolute bg-white rounded-full hover:cursor-pointer"
                src={PlayButton}
                alt="Play Button"
              />
            )}

            {isPlaying && (
              <>
                <style>
                  {`
                    .fade-out {
                      opacity: 0;
                      transition: opacity 1s ease-in-out;
                    }
                  `}
                </style>
                <div
                  onClick={handleVideoControl}
                  className={`${
                    isMobile ? "fade-out" : ""
                  } hidden pause-button absolute hover:cursor-pointer group-hover:block w-[70px] h-[70px] sm:w-auto`}
                >
                  <HiPause className="text-[200px] fill-[#272c6b] bg-white rounded-full w-[70px] h-[70px] sm:w-auto" />
                  <img
                    className="top-0 absolute w-[70px] h-[70px] sm:w-auto"
                    src={PurpleCircle}
                    alt=""
                  />
                </div>
              </>
            )}
          </div>

          <img
            className="absolute -right-4 top-0 w-[40px] sm:w-[55px] md:w-[70px] lg:w-[100px] xl:w-[128px] -z-[1]"
            src={Icon1}
            alt=""
          />
          <img
            className="absolute left-0 -bottom-1 sm:-bottom-1 md:-bottom-2 lg:-bottom-3 xl:-bottom-4 w-[40px] sm:w-[55px] md:w-[70px] lg:w-[100px] xl:w-[126px] -z-[1]"
            src={Icon2}
            alt=""
          />
          <img
            className="absolute bottom-3 -right-[30px] sm:-right-10 md:-right-12 lg:-right-20 xl:-right-24 sm:bottom-5 w-[55px] sm:w-[75px] md:w-[90px] lg:w-[120px] xl:w-[153px] -z-[1]"
            src={Icon3}
            alt=""
          />
        </div>
      </div>
    </section>
  );
};

export default VideoSection;
