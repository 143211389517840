import { useState } from "react";
// import Swiper core and required modules
import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import { isMobile } from "react-device-detect";
import { cn } from "../../utils/cn";

const LogoSlider = () => {
  const [logos, setLogos] = useState([
    "Asset 18.png",
    "Asset 19.png",
    "Asset 17.png",
    "Asset 11.png",
    "Asset 15.png",
    "Asset 12.png",
    "Asset 14.png",
    "Asset 13.png",
    "Asset 16.png",

    "Asset 18.png",
    "Asset 19.png",
    "Asset 17.png",
    "Asset 11.png",
    "Asset 15.png",
    "Asset 12.png",
    "Asset 14.png",
    "Asset 13.png",
    "Asset 16.png",

    "Asset 18.png",
    "Asset 19.png",
    "Asset 17.png",
    "Asset 11.png",
    "Asset 15.png",
    "Asset 12.png",
    "Asset 14.png",
    "Asset 13.png",
    "Asset 16.png",
  ]);

  const getItemOfScreenSize = (width) => {
    if (width < 600) {
      return 3;
    } else if (width < 800) {
      return 4;
    } else if (width < 1100) {
      return 5;
    } else {
      return 6;
    }
  };

  const isLandscape = window.innerWidth > window.innerHeight;

  return (
    <section className="flex w-full justify-center bg-white px-5">
      <Swiper
        // install Swiper modules
        modules={[Autoplay]}
        spaceBetween={1}
        slidesPerView={getItemOfScreenSize(window.innerWidth)}
        data-swiper-autoplay="2000"
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        loop={true}
        className="mySwiper"
      >
        {logos.map((logo, index) => {
          return (
            <SwiperSlide
              key={index}
              className="justify-center p-4"
              style={{ display: "flex" }}
            >
              <img
                className={cn("h-[40px] w-auto", {
                  "h-5 w-auto": isMobile && isLandscape,
                })}
                src={require("../../assets/images/Logos/" + logo)}
                alt=""
              />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </section>
  );
};
export default LogoSlider;
